import React from "react"

import correct from "../../images/guestPost/correct.svg"

function Requirements() {

  const formRequirements = [
    "Link do Google Docs com o conteúdo proposto;",
    "Detalhamento da palavra chave escolhida (volume mensal, etc);",
    "Detalhamento dos links externos no conteúdo;"
  ]

  return (
    <div className="w-full flex flex-col lg:flex-row md:items-center lg:justify-around lg:my-12 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">

      <h2 className="requirementsListH2Size text-4xl font-bold text-left text-black mb-5">O que você precisa nos enviar:</h2>

      <div className="flex flex-col">
        <ul className="text-altTextQS mb-5">
          {formRequirements.map((content, i) =>
            <li key={i}>
              <img className="inline mr-2" src={correct} alt="icone de confere" />
              {content}
            </li>
          )}
        </ul>
        <a className="underline text-purple font-semibold" href="https://docs.google.com/document/d/1urT_yLkmWDK9YQHzro2pdBaU7UIneC0glJtHYzJDg84/edit#heading=h.19z172bn99hy" target="_blank" rel="noreferrer">
          Veja um exemplo aqui.
        </a>
      </div>

      <a href="#start" className="w-52 h-24 hidden xl:flex items-center bg-middleGreen rounded-lg shadow-lg hover:shadow transition border-2 border-middleSupGreen text-xl font-bold  text-white text-center">
        Já tenho tudo e quero enviar meu artigo
      </a>

    </div>
  )
}

export default Requirements