import React from "react"

function FormularioGuestPost({ className }) {

  return (
    <iframe
      className={className}
      title="Formulário de Guest Post"
      src="https://forms.clickup.com/3009326/f/2vute-6863/23ID28O7DFNQGEEZH6"
      onwheel
      width="50%"
      height="50%"
      style={{background: "transparent"}}
    />
  )
}

export default FormularioGuestPost