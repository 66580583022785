import React from "react"

function RequirementCard({ image, alt, title, par }) {

  return (
    <li className="flex flex-col items-center px-2 requireCardSize rounded-lg text-center shadow-lg hover:shadow transition">
      <img className="h-8 w-8 my-6" src={image} alt={alt} />
      <h3 className="font-bold text-2xl text-middleGreen">{title}</h3>
      <div className="h-full flex items-center">
        <p className="leading-5">{par}</p>
      </div>
    </li>
  )
}

export default RequirementCard