import React from "react"

import { Helmet } from "react-helmet"

import Layout from "../components/newLayout/layout"
import Seo from "../components/seo"
import UnderHeader from "../components/underHeader/underHeader"
import FrontPage from "../components/guestPost/frontPage"
import RequirementsList from "../components/guestPost/requirementsList"
import FormularioGuestPost from "../components/guestPost/formularioGuestPost"

function GuestPost() {

  return (
    <Layout>

      <Helmet >
        <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
      </Helmet>

      <Seo title="Envie seu artigo" description="Publique seu artigo no Blog do QS e nos ajude a contribuir com a educação de milhares empreendedores." />

      <UnderHeader />

      <FrontPage />

      <RequirementsList />

      <FormularioGuestPost className="block xl:hidden mx-auto clickup-embed clickup-dynamic-height" />

    </Layout>
  )
}

export default GuestPost