import React from "react"

import RequiresCard from "./requirementCard"

import archive from "../../images/guestPost/archive.svg"
import key from "../../images/guestPost/key.svg"
import view from "../../images/guestPost/view.svg"
import link from "../../images/guestPost/link.svg"
import tag from "../../images/guestPost/tag.svg"

function Requirements() {

  const cardContent = [
    {
      img: archive,
      alt: "arquivo",
      title: "+1200 palavras",
      par: "e otimizado para SEO"
    },
    {
      img: key,
      alt: "chave",
      title: "Palavra-chave",
      par: "definida com antecedência e coerente com nosso nicho"
    },
    {
      img: view,
      alt: "paisagem",
      title: "2 imagens",
      par: "ou mais, dentro do conteúdo, com texto alternativo otimizado"
    },
    {
      img: link,
      alt: "corrente de link",
      title: "Até 2 links",
      par: "externos, no máximo"
    },
    {
      img: tag,
      alt: "tag",
      title: "Meta descrição",
      par: "contendo a palavra-chave"
    }
  ]

  return (
    <section className="flex flex-col md:items-center xl:items-start w-full mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg">

      <h2 className="mb-5 text-2xl font-bold text-left">Confira os <strong className="text-middleGreen">requisitos</strong>.</h2>

      <div className="requireArrow hidden md:flex xl:hidden space-x-1 rounded-full xl:ml-1 mb-8">
        <div className="inline-block bg-middleGreen transform -rotate-45 rounded-full"></div>
        <div className="inline-block bg-middleGreen transform rotate-45 rounded-full"></div>
      </div>

      <ul className="md:w-10/12 lg:w-full mb-8 flex flex-row justify-center xl:justify-start gap-x-4 gap-y-8 flex-wrap">
        {cardContent.map((card, i) => <RequiresCard key={i} image={card.img} alt={card.alt} title={card.title} par={card.par} /> )}
      </ul>

    </section>
  )
}

export default Requirements