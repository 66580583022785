import React from "react"

import Requirements from "./requirements"
import FormularioGuestPost from "./formularioGuestPost"

function FrontPage() {

  return (
    <section className="flex flex-row justify-between -mt-24 md:-mt-28 lg:-mt-36 xl:-mt-44 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">

      <div className="flex flex-col items-center xl:items-start xl:w-7/12">

        <h1 className="mb-8 md:mb-28 lg:mb-44 xl:mb-28 uppercase font-black text-4xl md:text-5xl lg:text-6xl md:text-center xl:text-left">você é nosso convidado</h1>

        <p className="mb-10 md:mb-6 md:w-8/12 text-4xl lg:text-5xl text-black md:text-center xl:text-left">Quer ter o <strong className="font-semibold">seu artigo publicado</strong> no nosso blog?</p>

        <p className="md:w-6/12 xl:w-8/12 mb-4 text-altTextQS text-lg md:text-center xl:text-left">Após conferir se o seu material cumpre todos os requisitos, preencha o formulário ao lado que o nosso time entrará em contato com você.</p>

        <Requirements />

      </div>

      <FormularioGuestPost className="hidden xl:block clickup-embed clickup-dynamic-height" />

    </section>
  )
}

export default FrontPage