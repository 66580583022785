import React from "react"

import headerPattern from "../../images/newLayout/headerPattern.svg"

function underHeader() {

  return (
    <section className="h-44 md:h-52 lg:h-60 flex flex-col justify-end mt-16 xl:mt-24 py-4 bg-middleGreen" style={{backgroundImage: `url(${headerPattern})`}}></section>
  )
}

export default underHeader